import { template as template_c4ecc73938f9430f92a8cd433efaf43d } from "@ember/template-compiler";
import EmojiPickerContent from "discourse/components/emoji-picker/content";
const EmojiPickerDetached = template_c4ecc73938f9430f92a8cd433efaf43d(`
  <EmojiPickerContent
    @close={{@close}}
    @term={{@data.term}}
    @didSelectEmoji={{@data.didSelectEmoji}}
    @context={{@data.context}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmojiPickerDetached;
